import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import { pages } from './Pages';
import Link from '@mui/material/Link';
import { useNavigate } from 'react-router-dom';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import { IconButton } from '@mui/material';

export default function Footer() {
    const navigate = useNavigate();

    return (
        <>
            <Container sx={{ padding: '1rem', textAlign: 'center' }}>
                {pages.map((page) => (
                    <Link
                        key={page.title}
                        component="button"
                        variant="caption"
                        onClick={() => { navigate(page.url) }}
                        sx={{ padding: "0.5rem" }}
                    >
                        {page.title}
                    </Link>
                ))}
                <Link
                    key="PrivacyPolicy"
                    component="button"
                    variant="caption"
                    onClick={() => { navigate('/privacy') }}
                    sx={{ padding: "0.5rem" }}
                >
                    Privacy Policy
                </Link>
                <Link
                    key="Gdpr"
                    component="button"
                    variant="caption"
                    onClick={() => { navigate('/gdpr') }}
                    sx={{ padding: "0.5rem" }}
                >
                    GDPR
                </Link>
            </Container>
            <Container sx={{ padding: '0.5rem', textAlign: 'center' }}>
                <IconButton color="primary" size="large" aria-label="LinkedIn" component={Link} href='https://www.linkedin.com/company/spenande' target="_blank">
                    <LinkedInIcon fontSize='large'/>
                </IconButton>
            </Container>
            <Container sx={{ padding: '1rem', textAlign: 'center' }}>
                <Typography variant="caption">
                    <strong>SPENANDE AB</strong><br />
                    Lodjursstråket 1<br />
                    417 51 Göteborg<br />
                    SWEDEN <br/>
                    🇸🇪
                </Typography>
            </Container>
            <Container sx={{ padding: '1rem', textAlign: 'center' }}>
                <Typography variant="caption" sx={{ fontSize: '0.6rem' }}>
                    &copy;2024 SPENANDE AB
                </Typography>
            </Container>
        </>
    );
}