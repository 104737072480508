import React from 'react'
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';
import { Helmet } from 'react-helmet-async';

const hemletData = {
    title: "SPENANDE - GDPR",
    description: "GDPR information"
}

export default function Gdpr() {
    const theme = useTheme();

    return (
        <>
            <Helmet>
                <title>{hemletData.title}</title>
                <link rel="canonical" href="/gdpr"></link>
                <meta name="description" content={hemletData.description}></meta>
                {/* <meta property="og:image" content="/cons.jpg" /> */}
                <meta property="og:title" content={hemletData.title} />
                <meta property="og:url" content="/gdpr" />
                <meta property="og:description" content={hemletData.description} />
                <meta property="og:type" content="website" />
            </Helmet>
            <Grid container spacing={0} maxWidth="md"
                sx={{
                    ml: 'auto', mr: 'auto',
                    backgroundColor: theme.palette.background.paper
                }}>
                <Grid item xs={12} sx={{ padding: '1rem' }}>
                    <Typography variant="body1" component='div'>
                        <article>
                        <h2>GDPR Compliance</h2>

                        <h3>1. Data Controller</h3>
                        <p>The data controller for the personal data collected on this website is <strong>SPENANDE AB</strong>. You can contact us at <Box component="img" sx={{ verticalAlign: 'middle' }} alt="" src="/eml.png" />.</p>

                        <h3>2. Personal Data We Collect</h3>
                        <p>We collect personal data you provide through our contact form, such as your name, email address, and any other details you choose to share. We may also collect data about how you use our website.</p>

                        <h3>3. Purpose of Data Collection</h3>
                        <p>We use your personal data to respond to your inquiries, provide the information or services you request, and improve our website and services.</p>

                        <h3>4. Legal Basis for Processing</h3>
                        <p>We process your personal data based on your consent when you submit the contact form. You can withdraw your consent at any time by contacting us.</p>

                        <h3>5. Data Retention</h3>
                        <p>We retain your personal data only as long as necessary to fulfill the purposes for which it was collected or as required by law.</p>

                        <h3>6. Data Security</h3>
                        <p>We implement appropriate technical and organizational measures to protect your personal data against unauthorized access, loss, or alteration.</p>

                        <h3>7. Your Rights</h3>
                        <p>Under GDPR, you have the right to access, correct, or delete your personal data. You also have the right to restrict or object to the processing of your data. To exercise these rights, please contact us at <Box component="img" sx={{ verticalAlign: 'middle' }} alt="" src="/eml.png" />.</p>

                        <h3>8. Data Transfers</h3>
                        <p>Your personal data may be transferred and stored in countries outside the EU. We ensure that appropriate safeguards are in place to protect your data.</p>

                        <h3>9. Complaints</h3>
                        <p>If you believe your data protection rights have been violated, you have the right to lodge a complaint with the relevant supervisory authority.</p>

                        <h3>10. Changes to This Policy</h3>
                        <p>We may update this GDPR compliance section from time to time. Any changes will be posted on this page with an updated revision date.</p>

                        </article>
                    </Typography>
                </Grid>
            </Grid>
        </>
    );
}
